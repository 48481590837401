import { render, staticRenderFns } from "./robot.vue?vue&type=template&id=b8d6aa86&scoped=true"
import script from "./robot.vue?vue&type=script&lang=js"
export * from "./robot.vue?vue&type=script&lang=js"
import style0 from "./robot.vue?vue&type=style&index=0&id=b8d6aa86&prod&lang=scss&scoped=true"
import style1 from "./robot.vue?vue&type=style&index=1&id=b8d6aa86&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8d6aa86",
  null
  
)

export default component.exports